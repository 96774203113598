import { useState, Fragment, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Sidebar from "../components/Sidebar";
import Box from "@mui/material/Box";
import { API_URL, alertMessage, loadUser } from "../actions/auth";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import { useParams } from "react-router-dom";
import TextField from "@mui/material/TextField";
import Fab from "@mui/material/Fab";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import axios from "axios";
import CircularProgress from "@mui/material/CircularProgress";
import { DatePicker } from "@mui/x-date-pickers";
import Modal from "@mui/material/Modal";
import Backdrop from "@mui/material/Backdrop";
import Fade from "@mui/material/Fade";
import moment from "moment";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import ButtonBase from "@mui/material/ButtonBase";
import DeleteIcon from "@mui/icons-material/Delete";
import { themeStyle, themeStyleDark } from "../components/Components";
import SyncIcon from "@mui/icons-material/Sync";
import PublishedWithChangesIcon from "@mui/icons-material/PublishedWithChanges";
import Calendar from "react-calendar";
import AccessAlarmIcon from "@mui/icons-material/AccessAlarm";
import { MobileTimePicker } from "@mui/x-date-pickers/MobileTimePicker";

function makeid(length) {
  var result = "";
  var characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

function Tasks() {
  const dispatch = useDispatch();
  function createAlert(type, title, message) {
    dispatch(alertMessage(type, title, message));
  }
  const navigate = useNavigate();
  const params = new URLSearchParams(window.location.search);
  const state = useSelector((state) => state);
  const [open2, setOpen2] = useState(false);
  const [open, setOpen] = useState(false);

  const [id, setId] = useState("");

  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedEndTime, setSelectedEndTime] = useState(null);
  const [selectedStartTime, setSelectedStartTime] = useState(null);

  const [taskSubject, setTaskSubject] = useState("");

  const colorList = [
    "#e5f5e0",
    state.auth.theme == "dark"
      ? themeStyleDark.secondaryButtonColor
      : themeStyle.secondaryButtonColor,
    "#fee6ce",
  ];
  const colorList2 = ["#31a354", "#3283c9", "#e6550d"];
  const colorList3 = ["#addd8e", "#9ecae1", "#a1d99b"];

  const [isLoading, setIsLoading] = useState(true);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const [email, setEmail] = useState("");
  const [users, setUsers] = useState([]);
  const [userIndex, setUserIndex] = useState(0);
  const [ogTasks, setOGTasks] = useState({});

  const [todayTasks, setTodayTasks] = useState([]);
  const [taskIndex, setTaskIndex] = useState(0);
  const [selectedTaskId, setSelectedTaskId] = useState("");
  const [selectedPage, setSelectedPage] = useState("ADD NEW TASK");

  const [totalWorkHours, setTotalWorkHours] = useState(0);
  const [workHoursPercent, setWorkHoursPercent] = useState(0);

  const handlePageSelect = (event) => {
    setSelectedPage(event.currentTarget.value);

    if (event.currentTarget.value == "ADD NEW TASK") {
      var tempTasks = JSON.parse(state.auth.user.task_tracker);
      setOGTasks(tempTasks);
      if (Object.keys(tempTasks).includes(moment().format("DD-MM-YYYY"))) {
        var tt = Array.from(tempTasks[moment().format("DD-MM-YYYY")]);

        setTodayTasks(tt);
        var ttt = 0;
        for (var i = 0; i < tt.length; i++) {
          if (tt[i]["end_time"].length != 0) {
            ttt += moment(tt[i]["start_time"], "hh:mm A").diff(
              moment(tt[i]["end_time"], "hh:mm A"),
              "minutes"
            );
          }
        }

        setTotalWorkHours(
          ((ttt / 60).toFixed(2) + " hours").replaceAll("-", "")
        );
        setWorkHoursPercent(
          (((ttt / 60 / 8) * 100).toFixed(2) + "%").replaceAll("-", "")
        );
      } else {
        setTodayTasks([]);
        setTotalWorkHours("0 hours");
        setWorkHoursPercent("0%");
      }
    } else {
      console.log(users);
      setUserIndex(0);
      var tempTasks = users[0]["task_tracker"];
      setOGTasks(tempTasks);
      if (Object.keys(tempTasks).includes(moment().format("DD-MM-YYYY"))) {
        var tt = Array.from(tempTasks[moment().format("DD-MM-YYYY")]);

        setTodayTasks(tt);
        var ttt = 0;
        for (var i = 0; i < tt.length; i++) {
          if (tt[i]["end_time"].length != 0) {
            ttt += moment(tt[i]["start_time"], "hh:mm A").diff(
              moment(tt[i]["end_time"], "hh:mm A"),
              "minutes"
            );
          }
        }
        setTotalWorkHours(
          ((ttt / 60).toFixed(2) + " hours").replaceAll("-", "")
        );
        setWorkHoursPercent(
          (((ttt / 60 / 8) * 100).toFixed(2) + "%").replaceAll("-", "")
        );
      } else {
        setTodayTasks([]);
        setTotalWorkHours("0 hours");
        setWorkHoursPercent("0%");
      }
    }
  };

  function getUsersList() {
    var userss = Object.keys(users).map((user, index) => (
      <MenuItem value={index}>{users[index]["full_name"]}</MenuItem>
    ));
    return userss;
  }

  const handleDateChange = (date) => {
    setSelectedDate(date);

    if (Object.keys(ogTasks).includes(moment(date).format("DD-MM-YYYY"))) {
      var tt = Array.from(ogTasks[moment(date).format("DD-MM-YYYY")]);

      setTodayTasks(tt);
      var ttt = 0;
      for (var i = 0; i < tt.length; i++) {
        if (tt[i]["end_time"].length != 0) {
          ttt += moment(tt[i]["start_time"], "hh:mm A").diff(
            moment(tt[i]["end_time"], "hh:mm A"),
            "minutes"
          );
        }
      }
      setTotalWorkHours(((ttt / 60).toFixed(2) + " hours").replaceAll("-", ""));
      setWorkHoursPercent(
        (((ttt / 60 / 8) * 100).toFixed(2) + "%").replaceAll("-", "")
      );
    } else {
      setTodayTasks([]);
      setTotalWorkHours("0 hours");
      setWorkHoursPercent("0%");
    }
  };

  function refresh() {
    axios
      .get(API_URL + "api/auth/user", {
        headers: {
          Authorization: state.auth.token,
        },
      })
      .then((res) => {
        dispatch({
          type: "USER_LOADED",
          payload: res.data,
        });
        var tempTasks = JSON.parse(res.data.task_tracker);
        setOGTasks(tempTasks);
        if (
          Object.keys(tempTasks).includes(
            moment(selectedDate).format("DD-MM-YYYY")
          )
        ) {
          var tt = Array.from(
            tempTasks[moment(selectedDate).format("DD-MM-YYYY")]
          );

          setTodayTasks(tt);
          var ttt = 0;
          for (var i = 0; i < tt.length; i++) {
            if (tt[i]["end_time"].length != 0) {
              ttt += moment(tt[i]["start_time"], "hh:mm A").diff(
                moment(tt[i]["end_time"], "hh:mm A"),
                "minutes"
              );
            }
          }
          setTotalWorkHours(
            ((ttt / 60).toFixed(2) + " hours").replaceAll("-", "")
          );
          setWorkHoursPercent(
            (((ttt / 60 / 8) * 100).toFixed(2) + "%").replaceAll("-", "")
          );
        } else {
          setTodayTasks([]);
          setTotalWorkHours("0 hours");
          setWorkHoursPercent("0%");
        }
      })
      .catch((err) => {
        dispatch({
          type: "AUTH_ERROR",
        });
      });
  }

  function getData() {
    var body = {};
    const config = {
      headers: {
        Authorization: state.auth.token,
        "Content-Type": "application/json",
      },
    };

    body["email"] = state.auth.user.email;
    axios.post(API_URL + "get_users", body, config).then((res) => {
      var tempUsers = res.data;
      var list = [];
      if (!state.auth.user.is_staff) {
        for (var i = 0; i < tempUsers.length; i++) {
          if (tempUsers[i]["reports_to"] == state.auth.user.email) {
            list.push(tempUsers[i]);
          }
        }
        setUsers(list);
      } else {
        setUsers(res.data);
      }

      setIsLoading(false);
    });
  }

  function handleUserChange(e) {
    setUserIndex(e.target.value);
    setOGTasks(users[e.target.value]["task_tracker"]);
    if (
      Object.keys(users[e.target.value]["task_tracker"]).includes(
        moment().format("DD-MM-YYYY")
      )
    ) {
      var tt = Array.from(
        users[e.target.value]["task_tracker"][moment().format("DD-MM-YYYY")]
      );

      setTodayTasks(tt);
      var ttt = 0;
      for (var i = 0; i < tt.length; i++) {
        if (tt[i]["end_time"].length != 0) {
          ttt += moment(tt[i]["start_time"], "hh:mm A").diff(
            moment(tt[i]["end_time"], "hh:mm A"),
            "minutes"
          );
        }
      }
      setTotalWorkHours(((ttt / 60).toFixed(2) + " hours").replaceAll("-", ""));
      setWorkHoursPercent(
        (((ttt / 60 / 8) * 100).toFixed(2) + "%").replaceAll("-", "")
      );
    } else {
      setTodayTasks([]);
      setTotalWorkHours("0 hours");
      setWorkHoursPercent("0%");
    }
  }

  function handleTaskChange(e) {
    setTaskIndex(e.target.value);
  }

  useEffect(() => {
    if (state.auth.isLoading) {
      dispatch({ type: "ENTRY_PAGE", payload: window.location.pathname });
      navigate("/", { replace: true });
    } else {
      setOGTasks(JSON.parse(state.auth.user.task_tracker));

      if (
        Object.keys(JSON.parse(state.auth.user.task_tracker)).includes(
          moment().format("DD-MM-YYYY")
        )
      ) {
        var tt = Array.from(
          JSON.parse(state.auth.user.task_tracker)[
            moment().format("DD-MM-YYYY")
          ]
        );

        setTodayTasks(tt);
        var ttt = 0;
        for (var i = 0; i < tt.length; i++) {
          if (tt[i]["end_time"].length != 0) {
            ttt += moment(tt[i]["start_time"], "hh:mm A").diff(
              moment(tt[i]["end_time"], "hh:mm A"),
              "minutes"
            );
          }
        }
        setTotalWorkHours(
          ((ttt / 60).toFixed(2) + " hours").replaceAll("-", "")
        );
        setWorkHoursPercent(
          (((ttt / 60 / 8) * 100).toFixed(2) + "%").replaceAll("-", "")
        );
      } else {
        setTodayTasks([]);
        setTotalWorkHours("0 hours");
        setWorkHoursPercent("0%");
      }
      if (
        state.auth.user.is_staff ||
        state.auth.user.is_manager ||
        state.auth.user.is_hr
      ) {
        getData();
      } else {
        setIsLoading(false);
      }
    }
  }, []);

  if (state.auth.refresh) {
    if (state.auth.user.is_staff || state.auth.user.is_manager) {
      getData();
    }
    refresh();

    dispatch({ type: "REFRESH_DATA", payload: null });
  }

  function updateTask(update, task) {
    setIsLoading(true);
    var body = {};
    const config = {
      headers: {
        Authorization: state.auth.token,
        "X-CSRFToken": state.auth.csrfToken,
        "Content-Type": "application/json",
      },
    };

    body["email"] = state.auth.user.email;
    body["start_date"] = task["start_date"];
    body["edit_time"] =
      update == "START"
        ? moment(selectedStartTime).format("hh:mm A")
        : moment(selectedEndTime).format("hh:mm A");
    body["start_or_end"] = update;
    body["id"] = task["id"];

    body = JSON.stringify(body);

    axios
      .post(API_URL + "edit_task", body, config)
      .then((res) => {
        createAlert("SUCCESS", "Success.", "Tasks updated");
        setIsLoading(false);
        setSelectedStartTime(null);
        setSelectedEndTime(null);
      })
      .catch((err) => {
        createAlert("ERROR", "Error", "An error occured.");
        setIsLoading(false);
        setSelectedStartTime(null);
        setSelectedEndTime(null);
      });
  }

  function addTask() {
    setIsLoading(true);
    var body = {};
    const config = {
      headers: {
        Authorization: state.auth.token,
        "X-CSRFToken": state.auth.csrfToken,
        "Content-Type": "application/json",
      },
    };
    body["id"] = makeid(5);
    body["email"] = state.auth.user.email;
    body["subject"] = taskSubject;
    body["edit_date"] = moment(selectedDate).format("DD-MM-YYYY");
    body["edit_time"] = moment().format("hh:mm A");

    body = JSON.stringify(body);
    setOpen2(false);

    axios
      .post(API_URL + "add_task", body, config)
      .then((res) => {
        createAlert("SUCCESS", "Success.", "Tasks updated");
        setIsLoading(false);
      })
      .catch((err) => {
        createAlert("ERROR", "Error", "An error occured.");
        setIsLoading(false);
      });
  }

  function deleteTask() {
    setIsLoading(true);
    var body = {};
    const config = {
      headers: {
        Authorization: state.auth.token,
        "X-CSRFToken": state.auth.csrfToken,
        "Content-Type": "application/json",
      },
    };

    body["email"] = state.auth.user.email;
    body["start_date"] = moment(selectedDate).format("DD-MM-YYYY");
    body["id"] = selectedTaskId;

    body = JSON.stringify(body);

    axios
      .post(API_URL + "delete_task", body, config)
      .then((res) => {
        createAlert("SUCCESS", "Success.", "Tasks updated");
        setOpen(false);
        setIsLoading(false);
      })
      .catch((err) => {
        createAlert("ERROR", "Error", "An error occured.");
        setIsLoading(false);
        setOpen(false);
      });
  }

  function getPageContent() {
    if (selectedPage == "ADD NEW TASK") {
      return (
        <div style={{ width: "100%" }}>
          <Grid container spacing={5}>
            <Grid item md={8}>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  fontSize: "30px",
                  marginBottom: "20px",
                }}
              >
                {"Total " + totalWorkHours + ", " + workHoursPercent}{" "}
              </div>
              {todayTasks.map((task, index) => (
                <div
                  style={{
                    cursor: "pointer",
                    minHeight: "80px",
                    padding: "10px",
                    marginBottom: "15px",
                    borderRadius: "15px",
                    backgroundColor: "white",
                    color: "black",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        lineHeight: "20px",
                        fontSize: "30px",
                        color: "black",
                        width: "60%",
                      }}
                    >
                      {task["subject"]}
                    </div>

                    <div style={{ display: "flex", alignItems: "center" }}>
                      <AccessAlarmIcon
                        style={{
                          fontSize: "30px",
                          color: "green",
                          marginRight: "10px",
                        }}
                      />
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <MobileTimePicker
                          value={
                            selectedStartTime == null
                              ? moment(task["start_time"], "hh:mm A").toDate()
                              : selectedStartTime
                          }
                          sx={{
                            height: "40px",
                            width: "100%",
                            marginBottom: "15px",
                          }}
                          closeOnSelect={false}
                          onChange={(time) => {
                            setSelectedStartTime(time);
                          }}
                          onAccept={(time) => {
                            updateTask("START", task);
                          }}
                          onClose={(time) => {
                            setSelectedStartTime(null);
                          }}
                          renderInput={(params) => (
                            <TextField
                              style={{
                                backgroundColor: "white",
                                marginTop: "2px",
                              }}
                              fullWidth
                              size="small"
                              {...params}
                            />
                          )}
                        />
                      </LocalizationProvider>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginLeft: "40px",
                      }}
                    >
                      <AccessAlarmIcon
                        style={{
                          fontSize: "30px",
                          color: "red",
                          marginRight: "10px",
                        }}
                      />

                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <MobileTimePicker
                          value={
                            selectedEndTime == null
                              ? task["end_time"].length == 0
                                ? moment().toDate()
                                : moment(task["end_time"], "hh:mm A").toDate()
                              : selectedEndTime
                          }
                          sx={{
                            height: "40px",
                            width: "100%",
                            marginBottom: "15px",
                          }}
                          closeOnSelect={false}
                          onChange={(time) => {
                            setSelectedEndTime(time);
                          }}
                          onAccept={(time) => {
                            updateTask("END", task);
                          }}
                          onClose={(time) => {
                            setSelectedStartTime(null);
                          }}
                          renderInput={(params) => (
                            <TextField
                              style={{
                                backgroundColor: "white",
                                marginTop: "2px",
                              }}
                              fullWidth
                              size="small"
                              {...params}
                            />
                          )}
                        />
                      </LocalizationProvider>
                    </div>
                    <ButtonBase
                      style={{
                        cursor: "pointer",
                        marginLeft: "15px",
                        display: "flex",
                        backgroundColor: "#ff3333",
                        borderRadius: "6px",
                        padding: "3px",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                      onClick={() => {
                        setSelectedTaskId(task["id"]);
                        setOpen(true);
                      }}
                    >
                      <DeleteIcon
                        style={{ color: "white", fontSize: "18px" }}
                      />
                    </ButtonBase>
                  </div>
                </div>
              ))}
            </Grid>
            <Grid item md={4}>
              <div
                style={{
                  marginTop: "-25px",
                  marginBottom: "-15px",
                  fontWeight: "bold",
                  fontSize: "24px",
                }}
              >
                <h3>Add new Task on</h3>
              </div>

              <div
                style={{
                  margin: "15px 0px 5px 0px",
                  color:
                    state.auth.theme == "dark"
                      ? themeStyleDark.primaryColor
                      : themeStyle.primaryColor,
                  fontSize: "16px",
                }}
              >
                Task Subject
              </div>
              <TextField
                value={taskSubject}
                style={{ marginTop: "2px" }}
                onChange={(e) => setTaskSubject(e.target.value)}
                fullWidth
                placeholder="Task Subject"
                variant="outlined"
                size="small"
              />
              <br></br>
              <br></br>

              <Button
                onClick={() => {
                  setOpen2(true);
                }}
                style={{
                  width: "100%",
                  fontSize: "15px",
                  height: "35px",
                  marginTop: "-1px",
                  backgroundColor: themeStyle.primaryColor,
                }}
                variant="contained"
                component="span"
              >
                <p>
                  add new task on {moment(selectedDate).format("Do MMM, YYYY")}
                </p>
              </Button>
              <br />
              <br />

              <h3>Calendar</h3>
              <div style={{ marginTop: "-35px", marginBottom: "15px" }}>
                <Box
                  borderRadius="16px"
                  style={{
                    marginTop: "40px",
                  }}
                >
                  <div className="calendar-container">
                    <Calendar
                      style={{
                        width: "100%",
                        backgroundColor: "black",
                      }}
                      onChange={handleDateChange}
                      value={selectedDate}
                    />
                  </div>
                </Box>
              </div>
              {isSubmitting ? (
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <CircularProgress />
                </div>
              ) : (
                ""
              )}
            </Grid>
          </Grid>
        </div>
      );
    } else {
      return (
        <Fragment>
          <Grid container spacing={2}>
            <Grid item md={8}>
              {todayTasks.length == 0 ? (
                <div
                  style={{
                    marginTop: "200px",
                    height: "100px",
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <div style={{ textAlign: "center" }}>
                    <Fab
                      size="large"
                      style={{
                        border: "3px solid #aaa",
                        boxShadow: "none",
                        backgroundColor: "transparent",
                      }}
                      aria-label="add"
                    >
                      <PriorityHighIcon
                        style={{
                          color: "grey",
                        }}
                      />
                    </Fab>
                    <br />
                    <h4 style={{ color: "grey" }}>No tasks for this user.</h4>
                  </div>
                </div>
              ) : (
                ""
              )}
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  fontSize: "30px",
                  marginBottom: "20px",
                }}
              >
                {"Total " + totalWorkHours + ", " + workHoursPercent}{" "}
              </div>
              {todayTasks.map((task, index) => (
                <div
                  style={{
                    cursor: "pointer",
                    minHeight: "50px",
                    padding: "10px",
                    marginBottom: "15px",
                    borderRadius: "15px",
                    backgroundColor: "white",
                    color: "black",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        lineHeight: "20px",
                        fontSize: "30px",
                        color: "black",
                        width: "60%",
                      }}
                    >
                      {task["subject"]}
                    </div>

                    <div style={{ display: "flex", alignItems: "center" }}>
                      <AccessAlarmIcon
                        style={{
                          fontSize: "30px",
                          color: "green",
                          marginRight: "10px",
                        }}
                      />
                      {" " + task["start_time"]}
                    </div>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <AccessAlarmIcon
                        style={{
                          fontSize: "30px",
                          color: "red",
                          marginRight: "10px",
                        }}
                      />
                      {task["end_time"].length == 0
                        ? "WORKING"
                        : " " + task["end_time"]}
                    </div>

                    <div
                      style={{
                        width: "100px",
                        display: "flex",
                        justifyContent: "center",
                        marginRight: "-15px",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          flexDirection: "column",
                          alignItems: "center",
                        }}
                      >
                        <b
                          style={{
                            marginTop: "5px",
                            color: "black",
                          }}
                        >
                          <span
                            style={{
                              fontWeight: "lighter",
                            }}
                          >
                            {task["status"]}
                          </span>
                        </b>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </Grid>
            <Grid item md={4}>
              <span
                style={{
                  color:
                    state.auth.theme == "dark"
                      ? themeStyleDark.primaryColor
                      : themeStyle.primaryColor,
                  fontSize: "14px",
                }}
              >
                Select User
              </span>
              <Select
                style={{
                  marginTop: "2px",
                  height: "40px",
                  marginBottom: "25px",
                }}
                variant={"outlined"}
                fullWidth
                value={userIndex}
                onChange={(e) => handleUserChange(e)}
              >
                {getUsersList()}
              </Select>
              <div style={{ marginTop: "-35px", marginBottom: "15px" }}>
                <Box
                  borderRadius="16px"
                  style={{
                    marginTop: "40px",
                  }}
                >
                  <div className="calendar-container">
                    <Calendar
                      style={{
                        width: "100%",
                        backgroundColor: "black",
                      }}
                      onChange={handleDateChange}
                      value={selectedDate}
                    />
                  </div>
                </Box>
              </div>
            </Grid>
          </Grid>
        </Fragment>
      );
    }
  }

  if (!state.auth.isLoading) {
    if (state.auth.isAuthenticated) {
      if (state.auth.isMfa) {
        if (isLoading) {
          return (
            <Fragment>
              <Sidebar />
              <div
                style={{
                  width: "100%",
                  height: "100vh",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: themeStyle.backgroundColor,
                }}
              >
                <CircularProgress style={{ color: themeStyle.primaryColor }} />
              </div>
            </Fragment>
          );
        } else {
          return (
            <Fragment>
              <Sidebar
                tabs={
                  state.auth.user.is_staff || state.auth.user.is_manager ? (
                    <Grid container spacing={1.5} justifyContent={"flex-end"}>
                      <Grid item md={4}>
                        {selectedPage == "USER TASKS" ? (
                          <Button
                            fullWidth
                            value={"USER TASKS"}
                            onClick={handlePageSelect}
                            p={2}
                            style={{
                              backgroundColor:
                                state.auth.theme == "dark"
                                  ? themeStyleDark.primaryColor
                                  : themeStyle.primaryColor,
                              color: "white",
                              height: "30px",
                            }}
                            borderRadius={6}
                          >
                            <h3
                              style={{
                                marginTop: "17px",
                                fontSize: "15px",
                              }}
                            >
                              USER TASKS
                            </h3>
                          </Button>
                        ) : (
                          <Button
                            fullWidth
                            value={"USER TASKS"}
                            onClick={handlePageSelect}
                            p={2}
                            style={{
                              backgroundColor:
                                state.auth.theme == "dark"
                                  ? themeStyleDark.secondaryButtonColor
                                  : themeStyle.secondaryButtonColor,
                              color: "white",
                              height: "30px",
                            }}
                            borderRadius={6}
                          >
                            <h3
                              style={{
                                marginTop: "17px",
                                fontSize: "15px",
                                color:
                                  state.auth.theme == "dark"
                                    ? themeStyleDark.secondaryButtonTextColor
                                    : themeStyle.secondaryButtonTextColor,
                              }}
                            >
                              USER TASKS
                            </h3>
                          </Button>
                        )}
                      </Grid>
                      <Grid item md={4}>
                        {selectedPage == "ADD NEW TASK" ? (
                          <Button
                            fullWidth
                            value="ADD NEW TASK"
                            onClick={handlePageSelect}
                            p={2}
                            style={{
                              backgroundColor:
                                state.auth.theme == "dark"
                                  ? themeStyleDark.primaryColor
                                  : themeStyle.primaryColor,
                              color: "white",
                              height: "30px",
                            }}
                            borderRadius={6}
                          >
                            <h3
                              style={{
                                marginTop: "17px",
                                fontSize: "15px",
                              }}
                            >
                              ADD NEW TASK
                            </h3>
                          </Button>
                        ) : (
                          <Button
                            fullWidth
                            value="ADD NEW TASK"
                            onClick={handlePageSelect}
                            p={2}
                            style={{
                              backgroundColor:
                                state.auth.theme == "dark"
                                  ? themeStyleDark.secondaryButtonColor
                                  : themeStyle.secondaryButtonColor,
                              color: "white",
                              height: "30px",
                            }}
                            borderRadius={6}
                          >
                            <h3
                              style={{
                                marginTop: "17px",
                                fontSize: "15px",
                                color:
                                  state.auth.theme == "dark"
                                    ? themeStyleDark.secondaryButtonTextColor
                                    : themeStyle.secondaryButtonTextColor,
                              }}
                            >
                              ADD NEW TASK
                            </h3>
                          </Button>
                        )}
                      </Grid>
                    </Grid>
                  ) : (
                    <Fragment />
                  )
                }
              />
              <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={() => setOpen(false)}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                  timeout: 500,
                }}
              >
                <Fade in={open}>
                  <div
                    style={{
                      position: "absolute",
                      top: "50%",
                      backgroundColor: themeStyle.backgroundColor,
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                      width: 400,
                      padding: "20px",
                    }}
                  >
                    <div style={{ marginTop: "-25px" }}>
                      <div
                        style={{
                          marginTop: "20px",
                          display: "flex",
                          justifyContent: "flex-end",
                        }}
                      >
                        <IconButton size="small" onClick={() => setOpen(false)}>
                          <CloseIcon
                            style={{
                              color:
                                state.auth.theme == "dark"
                                  ? themeStyleDark.primaryColor
                                  : themeStyle.primaryColor,
                            }}
                          />
                        </IconButton>
                      </div>
                      <h3>
                        Delete this task? This action is permanent cannot be
                        reversed. Continue?
                      </h3>
                      <br></br>
                      <br></br>
                      <div
                        style={{
                          display: "flex",
                          width: "100%",
                          justifyContent: "flex-end",
                        }}
                      >
                        <Button
                          onClick={() => setOpen(false)}
                          style={{ height: "30px" }}
                          variant="contained"
                        >
                          NO
                        </Button>
                        <Button
                          style={{ height: "30px", marginLeft: "10px" }}
                          variant="contained"
                          onClick={() => deleteTask()}
                        >
                          YES
                        </Button>
                      </div>
                    </div>
                  </div>
                </Fade>
              </Modal>
              <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open2}
                onClose={() => setOpen2(false)}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                  timeout: 500,
                }}
              >
                <Fade in={open2}>
                  <div
                    style={{
                      position: "absolute",
                      top: "50%",
                      backgroundColor: themeStyle.backgroundColor,
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                      width: 400,
                      padding: "20px",
                    }}
                  >
                    <div style={{ marginTop: "-25px" }}>
                      <div
                        style={{
                          marginTop: "20px",
                          display: "flex",
                          justifyContent: "flex-end",
                        }}
                      >
                        <IconButton size="small">
                          <CloseIcon
                            onClick={() => setOpen2(false)}
                            style={{
                              color:
                                state.auth.theme == "dark"
                                  ? themeStyleDark.primaryColor
                                  : themeStyle.primaryColor,
                            }}
                          />
                        </IconButton>
                      </div>
                      <h3>Start new task?</h3>
                      <br></br>
                      <br></br>
                      <div
                        style={{
                          display: "flex",
                          width: "100%",
                          justifyContent: "flex-end",
                        }}
                      >
                        <Button
                          onClick={() => setOpen2(false)}
                          style={{ height: "30px" }}
                          variant="contained"
                        >
                          NO
                        </Button>
                        <Button
                          style={{ height: "30px", marginLeft: "10px" }}
                          variant="contained"
                          onClick={() => addTask(moment())}
                        >
                          YES
                        </Button>
                      </div>
                    </div>
                  </div>
                </Fade>
              </Modal>

              <div
                style={{
                  padding: "100px 50px 90px 115px",
                  backgroundColor: themeStyle.backgroundColor,
                  height: "100vh",
                  width: "100vw",
                }}
              >
                {getPageContent()}
              </div>
            </Fragment>
          );
        }
      } else {
        dispatch({
          type: "AUTH_ERROR",
        });
        return <Navigate to="/login" />;
      }
    }
  } else {
    return (
      <Fragment>
        <div
          style={{
            width: "100%",
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: themeStyle.backgroundColor,
          }}
        >
          <CircularProgress style={{ color: themeStyle.primaryColor }} />
        </div>
      </Fragment>
    );
  }
}

export default Tasks;
